import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { APP_API_URL } from "./consts";
import { useAuthStore } from "./stores/auth.module";

const IBG_API_URL = `${APP_API_URL}/graphql`;

// Cache implementation
const cache = new InMemoryCache();

const customFetch = async (uri: any, options: any) => {
  const authStore = useAuthStore();

  // Setting the instituion ID
  const url = new URL(uri);
  if (authStore.institutionId) {
    url.searchParams.append("institution_id", authStore.institutionId);
  }

  const headers = new Headers();
  if (authStore.token) {
    headers.set("Authorization", authStore.token);
  }

  const response = await window.fetch(url, {
    ...options,
    headers,
  });

  if (response.status >= 400) {
    throw new Error("We have an error");
    // TODO: Add a better error loggin structure here
    // simpleError("global.anErrorOccurred");
    // sentryException(new Error("GraphQL Error"), {
    //   body: await response.text(),
    // });
  }

  return response;
};

// Create the graphql client
export const graphqlClient = new ApolloClient({
  cache,
  link: createHttpLink({
    uri: IBG_API_URL,
    fetch: customFetch,
  }),
});

export default { graphqlClient };
