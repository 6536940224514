"use strict";

import { LocalStorageKeys } from "@/enums";
import axios from "axios";

const apiclient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL || "https://api.ibgmanager.dk/ibg",
  // withCredentials: true,
  headers: {
    Authorization:
      sessionStorage.getItem(LocalStorageKeys.LoopbackAccessTokenId) ||
      localStorage.getItem(LocalStorageKeys.LoopbackAccessTokenId) ||
      import.meta.env.VITE_APP_DEV_TOKEN,
  },
});

let cancelToken: any;
apiclient.cancelAndGetToken = function getToken() {
  if (cancelToken) {
    cancelToken.cancel("Request cancelled");
  }
  cancelToken = axios.CancelToken.source();
  return cancelToken;
};

export default apiclient;
