import { graphqlClient } from "@/apollo";
import {
  Container_Type,
  SaveResourceDocument,
} from "@/graphql/generated-types";

const upload = async (
  container: Container_Type,
  body: string,
  institutionId: string,
) => {
  const response = await graphqlClient.query({
    query: SaveResourceDocument,
    variables: {
      resource: {
        body,
        container,
        institution: institutionId,
      },
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.resource?.save?.success) {
    return response.data.resource.save.id;
  }

  throw new Error(`File upload fail.`);
};

export default {
  upload,
};
