<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";

const status = ref<boolean>(false);

const onMessage = (event: any) => {
  status.value = event.detail.status;
};

onMounted(() => {
  document.addEventListener("custom-loading", onMessage);
});

onBeforeUnmount(() => {
  document.removeEventListener("custom-loading", onMessage);
});
</script>

<template>
  <div
    v-if="status"
    class="fixed inset-0 flex items-center justify-center z-50"
  >
    <img src="/preloader_timer.gif" />
  </div>
</template>

<script lang="ts">
export const showLoading = () =>
  document.dispatchEvent(
    new CustomEvent("custom-loading", {
      detail: { status: true },
    }),
  );

export const hideLoading = () =>
  document.dispatchEvent(
    new CustomEvent("custom-loading", {
      detail: { status: false },
    }),
  );
</script>
