import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m13.571 5.915 3.598 3.598a1.136 1.136 0 0 1 0 1.6L13.57 14.71a.994.994 0 0 1-1.696-.703v-1.82h-3.75a.936.936 0 0 1-.937-.937V9.375c0-.519.418-.937.937-.937h3.75v-1.82a.994.994 0 0 1 1.696-.703m-6.383-.29H5.313a.936.936 0 0 0-.938.938v7.5c0 .518.419.937.938.937h1.875a.936.936 0 1 1 0 1.875H5.313A2.813 2.813 0 0 1 2.5 14.063v-7.5A2.813 2.813 0 0 1 5.313 3.75h1.875a.936.936 0 1 1 0 1.875"
    }, null, -1)
  ])))
}
export default { render: render }