import { createI18n } from "vue-i18n";
import da from "./locales/da.json";
import de from "./locales/de.json";
import en from "./locales/en.json";
import no from "./locales/no.json";

const i18n = createI18n({
  legacy: false,
  locale: import.meta.env.VITE_APP_I18N_LOCALE || "da",
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "en",
  silentTranslationWarn: true,
  silentFallbackWarn: false,
  warnHtmlInMessage: false,
  warnHtmlMessage: false,
  missingWarn: false,
  fallbackWarn: false,
  messages: {
    da,
    de,
    en,
    no,
  },
});

export const globali18n = i18n.global;

export default i18n;
