import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M25.508 23.446a1.494 1.494 0 0 1 0 2.116 1.503 1.503 0 0 1-2.121 0L16 18.142 8.563 25.56a1.503 1.503 0 0 1-2.122 0 1.494 1.494 0 0 1 0-2.116l7.441-7.416-7.443-7.474a1.494 1.494 0 0 1 0-2.116 1.503 1.503 0 0 1 2.122 0L16 13.915l7.438-7.418a1.503 1.503 0 0 1 2.122 0 1.494 1.494 0 0 1 0 2.115l-7.441 7.416z"
    }, null, -1)
  ])))
}
export default { render: render }