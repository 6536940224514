import { createApolloProvider } from "@vue/apollo-option";
import { RestLink } from "apollo-link-rest";
import { setContext } from "apollo-link-context";
import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
} from "@apollo/client";
import { LocalStorageKeys } from "./enums";
const queryParams = new URLSearchParams(window.location.search);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// Http endpoint
let httpEndpoint;

if (
  !import.meta.env.VITE_APP_GRAPHQL_HTTP &&
  import.meta.env.VITE_APP_API_URL
) {
  httpEndpoint = new URL(import.meta.env.VITE_APP_API_URL + "/graphql");
} else {
  httpEndpoint = new URL(
    import.meta.env.VITE_APP_GRAPHQL_HTTP ||
      "https://api.ibgmanager.dk/ibg/graphql",
  );
}

const institutionId = queryParams.get("institution_id");
if (!httpEndpoint.searchParams.has("institution_id") && institutionId) {
  httpEndpoint.searchParams.append("institution_id", institutionId);
}

// Files URL root
export const filesRoot =
  import.meta.env.VITE_APP_FILES_ROOT ||
  httpEndpoint
    .toString()
    .substr(0, httpEndpoint.toString().indexOf("/graphql"));

const authLink = setContext((_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  const token =
    sessionStorage.getItem(LocalStorageKeys.LoopbackAccessTokenId) ||
    localStorage.getItem(LocalStorageKeys.LoopbackAccessTokenId) ||
    import.meta.env.VITE_APP_DEV_TOKEN;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    },
  };
});

const httpLink = createHttpLink({
  uri: httpEndpoint.toString(),
  // fetch,
});

const restLink = new RestLink({
  uri: import.meta.env.VITE_APP_API_URL || "https://api.ibgmanager.dk/ibg",
  // Remove trailing slashes in all GraphQL requests,
  // which may occur when the last passed in argument is empty string.
  customFetch: (uri: string, options: any) => {
    const url = uri.replace(/\/+$/, "");
    const urlRequest = new URL(url);

    const institutionId = queryParams.get("institution_id");
    if (!urlRequest.searchParams.has("institution_id") && institutionId) {
      urlRequest.searchParams.append("institution_id", institutionId);
    }
    return fetch(urlRequest.toString(), options);
  },
});

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint: httpEndpoint.toString(),
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.

  // link: authLink.concat(restLink),
  link: from([authLink, restLink, httpLink]),

  // Override default cache
  cache: new InMemoryCache(),

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const apolloClient = new ApolloClient({
    ...defaultOptions,
    ...options,
  });

  // Create vue apollo provider
  const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message,
      );
    },
  });

  return apolloProvider;
}
