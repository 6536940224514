<script setup lang="ts">
import { until } from "@vueuse/core";
import { watch, ref, onMounted, onUnmounted, toRefs } from "vue";

interface Props {
  open: boolean;
  backdropType?: string;
}

const props = withDefaults(defineProps<Props>(), {
  backdropType: "gray",
});

const dialogRef = ref();

const { backdropType } = toRefs(props);

const emit = defineEmits(["onOpen", "onClose"]);

const toggleDialog = async () => {
  await until(dialogRef).toBeTruthy({ timeout: 2000 });

  if (!dialogRef.value) {
    return;
  }

  if (props.open && dialogRef?.value?.showModal) {
    dialogRef?.value?.showModal();
    emit("onOpen");
  } else if (dialogRef?.value?.close) {
    dialogRef?.value?.close();
    emit("onClose");
  }
};

const handleCancelEvent = (event: any) => {
  event.preventDefault();
};

watch(() => props.open, toggleDialog);

onMounted(async () => {
  await until(dialogRef).toBeTruthy();
  dialogRef?.value?.addEventListener("cancel", handleCancelEvent);

  const timeout = setTimeout(() => {
    clearTimeout(timeout);
    toggleDialog();
  }, 50);
});

onUnmounted(() => {
  dialogRef?.value?.removeEventListener("cancel", handleCancelEvent);
});
</script>

<template>
  <dialog
    ref="dialogRef"
    :class="[
      'bg-neutral0 rounded-2xl animate-in fade-in duration-500',
      `backdrop-${backdropType}`,
      backdropType === 'gray'
        ? 'backdrop:bg-neutral1000 backdrop:opacity-80'
        : '',
    ]"
  >
    <slot></slot>
  </dialog>
</template>
