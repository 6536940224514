<template>
  <div id="app" v-if="isLoaded" @click="onBodyClick($event)">
    <error></error>
    <Navigation v-if="showNavigation" />
    <div id="content" :class="{ 'basis-content': showNavigation && isTablet }">
      <router-view v-slot="{ Component }">
        <ViewSpinner :component="Component" />
      </router-view>
    </div>
    <Confirmation />
    <SimpleError />
    <Loading />
  </div>
</template>

<script lang="ts" setup>
import "moment/dist/locale/da";
import "moment/dist/locale/de";
import "moment/dist/locale/nb";
import moment from "moment";
import * as Sentry from "@sentry/vue";
import Error from "@/components/generic/Error.vue";
import { useUserStore } from "@/stores/user.module";
import Navigation from "./components/layouts/Navigation.vue";
import { useBreakpoints } from "@vueuse/core";
import ViewSpinner from "@/components/layouts/ViewSpinner.vue";
import { BREAKPOINTS, MANAGER_TYPE } from "./consts";
import { useRoute } from "vue-router";
import { useAuthStore } from "./stores/auth.module";
import Confirmation from "@/stories/displays/Confirmation/Confirmation.vue";
import SimpleError from "@/stories/displays/SimpleError/SimpleError.vue";
import { useI18n } from "vue-i18n";
import Loading from "./stories/displays/Loading/Loading.vue";
import { validationSetup } from "./helpers/forms/validate";
import { createHead } from "unhead";
import { computed, onBeforeMount, ref } from "vue";
import emitter from "@/emitter";
import i18n from "./i18n";

type HeadLinkTypes = HTMLLinkElement | null;

const userStore = useUserStore();
const authStore = useAuthStore();
const breakpoints = useBreakpoints(BREAKPOINTS);
const isTablet = breakpoints.greaterOrEqual("tablet");
const route = useRoute();
const { t } = useI18n();

// This function loads the validation translations.
validationSetup();

// Auto-update the session storage
authStore.$subscribe(authStore.updateToStorage);

// Init actions of the auth store.
authStore.init();

const root = document.querySelector(":root") as HTMLElement | null;
const setQuillTranslation = (key: string, translationKey: string) => {
  if (root) {
    root.style.setProperty(`--quill-text-${key}`, `'${t(translationKey)}'`);
  }
};

// We have to set Quill translation keys as CSS variable because of Quill
// use the texts via CSS directly.
// https://github.com/slab/quill/issues/4331
setQuillTranslation("visit-url", "quill.visitURL");
setQuillTranslation("edit", "global.edit");
setQuillTranslation("remove", "global.remove");
setQuillTranslation("enter-link", "quill.enterLink");
setQuillTranslation("save", "global.save");

const isLoaded = ref(false);

const locale = computed(() => {
  return userStore.locale;
});

const userType = computed(() => {
  return userStore.type;
});

const showNavigation = computed(() => {
  return (
    isLoaded.value && // no blink effect
    !window.$features.iframe && // Iframe means this is the planner. No navigation on planner
    route.name !== undefined && // no blink effect
    route.name !== "relativeProfileCreation" // No navigation on relative-profile-creation view
  );
});

const onBodyClick = (event: Event) => {
  emitter.emit("body-click", event);
};

const initApp = async () => {
  createHead();

  // Setting the manifest
  if (MANAGER_TYPE === "BP_MANAGER") {
    const manifest = document.getElementById("head-manifest") as HeadLinkTypes;
    const favicon = document.getElementById("head-favicon") as HeadLinkTypes;

    if (manifest && favicon) {
      manifest.href = "/manifests/bp/index.json";
      favicon.href = "/manifests/bp/icon512_rounded.png";
    }
  }

  if (window.$features.iframe) {
    await userStore.fetchUser();
    Sentry.setUser({
      id: `${userStore.userId}`,
      username: `${userType.value}-${userStore.userId}`,
    });
    i18n.global.locale.value = locale.value as any;
    moment.locale(locale.value);
  }

  isLoaded.value = true;
};

onBeforeMount(() => {
  initApp();
});
</script>

<style lang="scss">
@use "tailwindcss/base" as *;
@use "tailwindcss/components" as *;
@use "tailwindcss/utilities" as *;

@font-face {
  font-family: "IBG";
  src: url("./assets/fonts/ibgv2.eot");
  src:
    url("./assets/fonts/ibgv2.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/ibgv2.woff") format("woff"),
    url("./assets/fonts/ibgv2.ttf") format("truetype"),
    url("./assets/fonts/ibgv2.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.font-ibg {
  font-family: "IBG";
  font-size: 32px;
  font-weight: normal;
  font-style: normal;

  &.activity::before {
    content: "a";
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
  }
}

body {
  font-family: "Arial", "Helvetica", sans-serif;
  font-size: 15px;
}

/**
* Remove outline etc
*/
button {
  -webkit-appearance: none;
  appearance: none;
  font-weight: bold;
}
button::-moz-focus-inner {
  border: 0;
}
.tooltip {
  background: #000;
  color: #fff;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  max-width: 150px;
  text-align: center;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  font-size: 1rem;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
  padding-right: 30px !important;
}

.form-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #3f83f8;
  background-color: #ffffff;
  border-color: #d2d6dc;
  border-width: 1px;
  border-radius: 0.25rem;
}

.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.drop-down button {
  display: block;
  background-color: white;
  color: #696969;
}
.drop-down button.selected {
  color: white;
  background-color: #5f7289;
}

.drop-down button {
  border-radius: 6px;
  border: 1px #d9d9d9 solid;
  padding: 8px 20px;
}

.basis-content {
  margin-left: 218px;
}
</style>
