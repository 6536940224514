import type { DirectiveBinding } from "vue";

interface CustomElement extends HTMLElement {
  event: (event: MouseEvent) => void;
}

export default {
  mounted(el: CustomElement, { value }: DirectiveBinding) {
    el.event = function (event: MouseEvent) {
      if (
        !(
          el == event.target ||
          el.contains(event.target as Node) ||
          (value.excludeString &&
            [...document.body.querySelectorAll(value.excludeString)].includes(
              event.target,
            ))
        )
      ) {
        value.handler(event);
      }
    };
    document.body.addEventListener("click", el.event);
  },
  unmounted(el: CustomElement) {
    document.body.removeEventListener("click", el.event);
  },
};
