import apiclient from "@/services/apiclient";

export default {
  getFilters(callback: any) {
    apiclient
      .get("planner/get-filters")
      .then((res: any) => {
        callback(res.data);
      })
      .catch((e: any) => {
        console.warn(e);
      });
  },
};
