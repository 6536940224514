/**
 * These are the possible keys to use localStorage.
 */
export enum LocalStorageKeys {
  Modules = "modules",
  CustomFilter = "customFilter",
  PlannerFilters = "plannerFilters",
  DefaultPlannerView = "default-planner-view",
  LoopbackAccessTokenId = "$LoopBack$accessTokenId",
}
