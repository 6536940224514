import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentcolor",
      d: "M4 7.286C4 6.576 4.576 6 5.286 6h21.428C27.427 6 28 6.576 28 7.286c0 .712-.573 1.285-1.286 1.285H5.286C4.576 8.571 4 7.998 4 7.286m0 8.571c0-.712.576-1.286 1.286-1.286h21.428c.713 0 1.286.574 1.286 1.286s-.573 1.286-1.286 1.286H5.286c-.71 0-1.286-.573-1.286-1.286m22.714 9.857H5.286a1.284 1.284 0 1 1 0-2.571h21.428c.713 0 1.286.573 1.286 1.286 0 .712-.573 1.285-1.286 1.285"
    }, null, -1)
  ])))
}
export default { render: render }