<script setup lang="ts">
import BPLogoIcon from "@/assets/svg/bp-logo-white.svg?component";
import IBGLogoIcon from "@/assets/svg/ibg-logo-white.svg?component";
import RightFromBracketIcon from "@/assets/svg/right-from-bracket.svg?component";
import MegaphoneIcon from "@/assets/svg/megaphone.svg?component";
import PersonRunningIcon from "@/assets/svg/person-runnning.svg?component";
import UsersIcon from "@/assets/svg/users.svg?component";
import BarsIcon from "@/assets/svg/bars.svg?component";
import XMarkIcon from "@/assets/svg/xmark.svg?component";
import NavMenu from "@/stories/navigations/NavMenu/NavMenu.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { useBreakpoints } from "@vueuse/core";
import { BREAKPOINTS, MANAGER_TYPE, MOBILE_NAV_HEIGHT } from "../../consts";
import { ref } from "vue";
import MobileNavMenu from "@/stories/navigations/NavMenu/MobileNavMenu.vue";
import Line from "@/stories/layout/Line/Line.vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "../../stores/auth.module";

const LINKS = [
  { value: "/news", title: "global.news", icon: MegaphoneIcon },
  { value: "/activities", title: "global.activities", icon: PersonRunningIcon },
  { value: "/employees", title: "global.employees", icon: UsersIcon },
];

const route = useRoute();
const { t } = useI18n();
const authStore = useAuthStore();
const breakpoints = useBreakpoints(BREAKPOINTS);
const isTablet = breakpoints.greaterOrEqual("tablet");

const isMobileMenuActive = ref(false);

const translatedLinks = computed(() => {
  return LINKS.map((link: any) => {
    return {
      ...link,
      title: t(link.title),
    };
  });
});

const currentRoute = computed(() => {
  const link = LINKS.find((item: any) =>
    route.path.toString().startsWith(item.value),
  );

  return link || LINKS.at(0);
});

const handleLogout = async () => {
  await authStore.logout();
};
</script>

<template>
  <!-- eslint-disable vue/no-multiple-template-root -->
  <nav
    class="bg-darkOcean600 left-0 w-218 fixed top-0 h-dvh flex flex-col justify-between text-white"
    id="mobile-navigation"
    v-if="authStore.login && isTablet"
  >
    <div class="h-20 flex justify-center items-center">
      <RouterLink to="/">
        <BPLogoIcon v-if="MANAGER_TYPE === 'BP_MANAGER'" />
        <IBGLogoIcon v-if="MANAGER_TYPE === 'IBG_MANAGER'" />
      </RouterLink>
    </div>
    <div class="flex-grow px-4 flex flex-col gap-1">
      <NavMenu
        v-for="link of translatedLinks"
        :key="link.value"
        :icon="link.icon"
        :title="link.title"
        :to="link.value"
        :active="currentRoute?.value === link.value"
      />
    </div>
    <div class="px-4 pb-8">
      <div class="bg-neutral500 opacity-20 mb-2" style="height: 1px"></div>
      <button
        type="button"
        class="p-3 h50 text-neutral0 flex gap-2 items-center"
        @click="handleLogout"
      >
        <RightFromBracketIcon />
        {{ $t("main.logout") }}
      </button>
    </div>
  </nav>
  <nav
    v-if="authStore.login && !isTablet"
    class="bg-white flex items-center justify-between px-4 border-b border-neutral300 relative"
    :style="{ height: MOBILE_NAV_HEIGHT }"
  >
    <RouterLink to="/">
      <BPLogoIcon />
    </RouterLink>
    <button
      type="button"
      @click="isMobileMenuActive = !isMobileMenuActive"
      class="transition-all"
    >
      <BarsIcon v-if="!isMobileMenuActive" class="animate-in fade-in" />
      <XMarkIcon v-if="isMobileMenuActive" class="animate-in fade-in" />
    </button>
    <div
      v-if="isMobileMenuActive"
      :class="`fixed left-0 w-full bg-white py-10 px-8 animate-in fade-in z-50`"
      :style="{
        top: MOBILE_NAV_HEIGHT,
        height: `calc(100dvh - ${MOBILE_NAV_HEIGHT})`,
      }"
    >
      <div class="flex flex-col gap-8 pb-3">
        <MobileNavMenu
          v-for="link of translatedLinks"
          :key="link.value"
          :icon="link.icon"
          :title="link.title"
          :to="link.value"
          :active="currentRoute?.value === link.value"
          @click="isMobileMenuActive = false"
        />
      </div>
      <Line padding="lg" />
      <div class="pt-3">
        <button
          type="button"
          class="h600 flex items-center gap-3"
          @click="handleLogout"
        >
          <RightFromBracketIcon />
          {{ $t("main.logout") }}
        </button>
      </div>
    </div>
  </nav>
</template>
