/**
 * Simple alternative to the unsupported focus-visible.
 * Adds ".focus-visible" class to body when tab is used, and removes it when clicking.
 * Add selectors like:
 *  .focus-visible .element-class:focus
 */
window.addEventListener("DOMContentLoaded", () => {
  document.body.addEventListener("keydown", (event: any) => {
    if (event.code === "Tab" || event.keyCode === 9) {
      document.body.classList.add("focus-visible");
    }
  });
  document.body.addEventListener("mousedown", () => {
    document.body.classList.remove("focus-visible");
  });
});
