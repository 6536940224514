/**
 * This API service is a part of Relation profile creation.
 * https://proreact.atlassian.net/browse/IBG-7037
 *
 * Example: http://localhost:5173/relative-profile-creation?t=XXXXX
 */
const DOMAIN = "https://api.ibgmanager.dk/api";

const validateToken = async (code: string) => {
  return await fetch(`${DOMAIN}/Logins/ValidateInstToken/${code}`);
};

const create = async (code: string, data: any) => {
  return await fetch(`${DOMAIN}/Logins/createNOK/${code}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-type": "application/json" },
  });
};

const upload = async (code: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  return await fetch(`${DOMAIN}/Logins/UploadConnectPicture/${code}`, {
    method: "POST",
    body: formData,
  });
};

export default {
  validateToken,
  create,
  upload,
};
