import activities from "./activities";
import employees from "./employees";
import news from "./news";
import planner from "./planner";
import profile from "./profile";
import relativeProfile from "./relativeProfile";
import resource from "./resource";
import user from "./user";

export default {
  activities,
  employees,
  news,
  planner,
  profile,
  relativeProfile,
  resource,
  user,
};
