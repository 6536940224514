import type { StateCodes } from "@/types";
import type { Pinia, PiniaPluginContext, Store } from "pinia";

const STORE_MAP: Record<StateCodes, Store | null> = {
  NEWS: null,
  ADD_NEWS: null,
  ACTIVITIES: null,
  ADD_ACTIVITIES: null,
  EMPLOYEES: null,
  ADD_EMPLOYEES: null,
};

/**
 * We should set the all stores in a MAP so that we can access them by using
 * their name.
 *
 * Check the `getStore()` function below.
 */
export const setStores = (pinia: Pinia) => {
  pinia.use(({ store }: PiniaPluginContext) => {
    STORE_MAP[store.$id as StateCodes] = store;
  });
};

/**
 * We do this because there are some component that uses different stores.
 *
 * For example, let assume that you have a Form.vue component. We want to use
 * same component for editing and adding new form value. We want to create a new
 * modal (Dialog) to add some data while the editing version is on the back.
 *
 * In this case, Form.vue should be able to use different pinia stores.
 *
 * Check the `news.module.js` file. You can see the same schema is used in two
 * different stores. If you search the :is="NEWS_TAB_MAP[newsStore.currentTab]"
 * value in the project, you can see that we allow to components to use different
 * stores.
 *
 * <Form storeName="NEWS" />
 *
 * Inside the Form.vue;
 * const store = getStore(props.storeName);
 */
export const getStore = <T>(name: StateCodes) => {
  const store = STORE_MAP[name] as T;

  if (store) {
    return store;
  }

  throw new Error(`The state is not found: ${name}`);
};
