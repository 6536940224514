import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M13.124 3.906a1.406 1.406 0 1 0-2.813 0 1.406 1.406 0 0 0 2.813 0M7.432 7.641a1.55 1.55 0 0 1 1.263-.445L7.78 9.94c-.273.82.05 1.722.785 2.182l2.525 1.579-.744 2.601a.938.938 0 1 0 1.805.516l.84-2.941a1.406 1.406 0 0 0-.606-1.58l-1.664-1.04.905-2.413.15.36a2.82 2.82 0 0 0 2.598 1.732h.624a.936.936 0 1 0 0-1.875h-.624a.94.94 0 0 1-.864-.577l-.185-.44a3.75 3.75 0 0 0-2.358-2.141l-1.427-.44a3.418 3.418 0 0 0-3.43.852l-.68.678A.939.939 0 0 0 6.758 8.32l.677-.677zm-1.01 5.17H4.687a.936.936 0 1 0 0 1.875h2.04c.556 0 1.06-.328 1.285-.835l.337-.758-.278-.176a2.8 2.8 0 0 1-1.11-1.315z"
    }, null, -1)
  ])))
}
export default { render: render }