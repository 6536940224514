import { defineStore } from "pinia";

export interface IError {
  status?: string;
  url?: string;
  method?: string;
  code?: string;
  message?: string;
  correlationId?: string;
}

export interface ICommonState {
  error: IError | null;
}

export const useCommonStore = defineStore("common", {
  state: (): ICommonState => ({
    error: null,
  }),
  actions: {
    setError(payload: IError | null) {
      this.error = payload || null;
    },
  },
});
