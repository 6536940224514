import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M16.667 4.375c.46 0 .833.372.833.833v10a.832.832 0 1 1-1.667 0v-10c0-.46.373-.833.834-.833m-12.5 4.122L15 5.208v10l-3.414-1.036a2.499 2.499 0 0 1-4.92-.63c0-.287.05-.565.139-.82l-2.638-.803a.834.834 0 0 1-1.667-.044V8.542a.834.834 0 0 1 1.667-.044m6.224 5.31-2.386-.724a1.25 1.25 0 1 0 2.385.724"
    }, null, -1)
  ])))
}
export default { render: render }