import { defineStore } from "pinia";
import apiclient from "@/services/apiclient";

export const useUserStore = defineStore("user", {
  state: () => ({
    email: null,
    type: null,
    userId: null,
    locale: "da",
    roles: null,
  }),
  getters: {
    isNotInstitution: (state: any) =>
      state.type && state.type.toLowerCase() !== "institution",
    isSupport: (state: any) => state.roles && state.roles.includes("support"),
  },
  actions: {
    async fetchUser() {
      return apiclient
        .get("common/get-user/")
        .then((response: any) => {
          this.setUser(response.data);
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    },
    setUser(payload: any) {
      this.email = payload.email;
      this.type = payload.type;
      this.userId = payload.id;
      this.locale = payload.locale;
      this.roles = payload.roles;
    },
  },
});
