<template>
  <div
    class="fixed z-50 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    v-if="error"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl isolate transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button
            @click.stop="close"
            type="button"
            class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="sr-only">{{ $t("global.close") }}</span>
            <!-- Heroicon name: outline/x -->
            <i class="fal fa-times" />
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
          >
            <span class="fas fa-exclamation-triangle text-red-600 text-2xl" />
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-title"
            >
              {{ $t("global.error_occurred") }}
            </h3>
            <h4
              class="mt-2"
              v-if="['put', 'post', 'patch'].includes(error?.method || '')"
            >
              {{ $t("global.data_not_saved") }}
            </h4>
            <div
              class="mt-4 border rounded border-gray-200 p-2 overflow-y-auto max-h-32 text-xs leading-normal text-gray-500 font-mono"
            >
              <p>{{ error.message }}</p>

              <p class="mt-2">{{ error.url }}</p>

              <p class="mt-2" v-if="error.correlationId">
                Error ID: {{ error.correlationId }}
              </p>
            </div>
            <div class="mt-2 text-sm leading-normal text-gray-500">
              <slot />
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            @click.stop="close"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent focus:border-indigo-700 shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm sm:leading-normal"
          >
            {{ $t("global.ok") }}
          </button>

          <button
            @click.stop="copyMessage"
            type="button"
            :title="$t('global.copy')"
            class="mx-2 mt-3 w-full inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm sm:leading-normal"
          >
            <span class="fal fa-clipboard" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useCommonStore } from "@/stores/common.module";

export default {
  setup() {
    const { error, setError } = useCommonStore();
    return {
      error,
      setError,
    };
  },
  methods: {
    close() {
      this.setError(null);
    },
    copyMessage() {
      let messageParts = [this.error?.message, this.error?.url];
      if (this.error?.correlationId) {
        messageParts.push(`Error ID: ${this.error.correlationId}`);
      }
      navigator.clipboard.writeText(messageParts.join("\n\n"));
    },
  },
};
</script>
