import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M5.875 7.5a1.874 1.874 0 1 1-.001-3.749A1.874 1.874 0 0 1 5.875 7.5m8.625 0a1.875 1.875 0 1 1-.001-3.749A1.875 1.875 0 0 1 14.5 7.5m-12 3.25c0-1.38 1.12-2.5 2.5-2.5h1.002c.372 0 .726.082 1.045.227a3.001 3.001 0 0 0 .97 2.773H3a.5.5 0 0 1-.499-.5m9.5.5h-.017a2.99 2.99 0 0 0 .97-2.773 2.5 2.5 0 0 1 1.045-.227H15c1.38 0 2.501 1.12 2.501 2.5a.5.5 0 0 1-.5.5zM12.25 9a2.25 2.25 0 1 1-4.501-.001A2.25 2.25 0 0 1 12.25 9M5.5 15.124C5.5 13.4 6.9 12 8.624 12h2.752c1.725 0 3.124 1.4 3.124 3.124a.626.626 0 0 1-.626.626H6.126a.626.626 0 0 1-.626-.626"
    }, null, -1)
  ])))
}
export default { render: render }