<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import BaseDialog from "../../dialogs/BaseDialog.vue";
import Button from "../../buttons/Button/Button.vue";
import { IConfirmationDetail } from "@/interfaces";

const TEXT_KEYS = {
  default: {
    title: "confirmation.title",
    description: "confirmation.description",
    cancelText: "templates.cancel",
    confirmText: "confirmation.confirm",
  },
  unsavedChanges: {
    title: "global.unsavedChanges",
    description: "global.unsavedChangesConfirm",
    cancelText: "templates.cancel",
    confirmText: "global.unsavedChangesConfirmYes",
  },
};

const message = ref<IConfirmationDetail | undefined>(undefined);

const onMessage = (event: any) => {
  message.value = event.detail;
};

const closeMessage = () => {
  if (typeof message.value?.onCancel === "function") {
    message.value?.onCancel();
  }
  message.value = undefined;
};

const confirm = () => {
  if (typeof message.value?.onConfirm === "function") {
    message.value?.onConfirm();
  }

  message.value = undefined;
};

onMounted(() => {
  document.addEventListener("custom-confirmation", onMessage);
});

onBeforeUnmount(() => {
  document.removeEventListener("custom-confirmation", onMessage);
});
</script>

<script lang="ts">
export const confirmation = ({
  onConfirm,
  onCancel,
  variant = "error",
  textType = "default",
}: IConfirmationDetail) => {
  document.dispatchEvent(
    new CustomEvent("custom-confirmation", {
      detail: { onConfirm, onCancel, textType, variant },
    }),
  );
};
</script>

<template>
  <BaseDialog :open="!!message" @on-close="$emit('close')">
    <div
      class="overflow-hidden w-full text-center laptop:w-520 pt-12 pb-8 px-14"
    >
      <div class="relative h800 text-neutral1000">
        <div class="flex items-center justify-center">
          {{ $t(TEXT_KEYS[message?.textType || "default"].title) }}
        </div>
      </div>
      <div class="b300 py-3 text-center">
        {{ $t(TEXT_KEYS[message?.textType || "default"].description) }}
      </div>
    </div>
    <div class="tablet:flex tablet:justify-center gap-3 py-4 px-6">
      <Button
        :text="$t(TEXT_KEYS[message?.textType || 'default'].cancelText)"
        type="white"
        @click="closeMessage"
        :fullWidth="true"
        class="mobile:mb-2"
      />
      <Button
        :fullWidth="true"
        :text="$t(TEXT_KEYS[message?.textType || 'default'].confirmText)"
        :type="message?.variant || 'error'"
        @click="confirm"
      />
    </div>
  </BaseDialog>
</template>
