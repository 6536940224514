<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import Button from "../../buttons/Button/Button.vue";
import BaseDialog from "../../dialogs/BaseDialog.vue";

const message = ref(null);
const translationValues = ref(undefined);

const onMessage = (event: any) => {
  message.value = event.detail.message;
  translationValues.value = event.detail.translationValues;
};

const handleOk = () => {
  message.value = null;
  translationValues.value = undefined;
};

onMounted(() => {
  document.addEventListener("custom-simple-error", onMessage);
});

onBeforeUnmount(() => {
  document.removeEventListener("custom-simple-error", onMessage);
});
</script>

<script lang="ts">
export const simpleError = (message: any, translationValues: any = {}) =>
  document.dispatchEvent(
    new CustomEvent("custom-simple-error", {
      detail: { message, translationValues },
    }),
  );
</script>

<template>
  <BaseDialog v-if="!!message" :open="!!message" @on-close="$emit('close')">
    <div class="overflow-hidden w-full laptop:w-480 pt-12 pb-8 px-20">
      <div class="relative h800 text-neutral1000">
        <div class="flex items-center justify-center">
          {{ $t("global.error_occurred") }}
        </div>
      </div>
      <div class="b300 py-3 text-center">
        {{ $t(message, translationValues) }}
      </div>
    </div>
    <div class="flex justify-end gap-3 py-4 px-6">
      <Button
        :text="$t('global.ok')"
        type="error"
        @click="handleOk"
        size="lg"
        full-width
      />
    </div>
  </BaseDialog>
</template>
