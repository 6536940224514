import { graphqlClient } from "@/apollo";
import { EmployeeInput, SaveProfileDocument } from "@/graphql/generated-types";

const save = async (employee: EmployeeInput) => {
  const response = await graphqlClient.query({
    query: SaveProfileDocument,
    variables: {
      employee,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.user?.saveProfile?.success) {
    return;
  }

  throw new Error("An error occurred on creting profile");
};

export default {
  save,
};
